import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { BankAccount } from '../model';

@Injectable({
  providedIn: 'root',
})
export class BankCardInfoService {
  private bankCard: BehaviorSubject<BankAccount> = new BehaviorSubject<BankAccount>(new BankAccount());

  constructor() {}

  public getUpdatedBankCard(): Observable<BankAccount> {
    return this.bankCard.asObservable();
  }

  public setUpdatedBankCard(value: BankAccount): void {
    this.bankCard.next(value);
  }
}
