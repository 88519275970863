import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsDatepickerConfig, BsDatepickerDirective, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { deLocale } from 'ngx-bootstrap/locale';

@Directive({
  selector: '[appDatepicker]',
  providers: [BsDatepickerDirective],
  exportAs: 'appDatepicker', // Exportiere die Directive als Referenz
})
export class DatepickerDirective implements OnInit {
  @Input() dateFormat = 'DD.MM.YYYY';
  @Input() adaptivePosition = true;
  @Input() showWeekNumbers = false;
  @Input() containerClass = 'theme-default';
  @Input() customConfig: Partial<BsDatepickerConfig>;

  @Output() dateChange = new EventEmitter<string>(); // Emittiert nur den formatierten String

  private inputElement: HTMLInputElement;

  constructor(
    private el: ElementRef,
    private localeService: BsLocaleService,
    public bsDatepicker: BsDatepickerDirective,
  ) {
    defineLocale('de', deLocale);
    this.localeService.use('de');
  }

  ngOnInit() {
    // Create base config with default values
    const config = new BsDatepickerConfig();
    config.dateInputFormat = this.dateFormat;
    config.containerClass = this.containerClass || 'theme-default';

    // Override with custom config if provided
    if (this.customConfig) {
      Object.assign(config, this.customConfig);
    }

    this.bsDatepicker.bsConfig = config;
    this.bsDatepicker.setConfig();

    this.inputElement = this.el.nativeElement;

    this.bsDatepicker.bsValueChange.subscribe((date: Date) => {
      if (date && this.inputElement) {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const displayFormat = `${day}.${month}.${year}`;

        this.inputElement.value = displayFormat;

        const event = new Event('input', { bubbles: true });
        this.inputElement.dispatchEvent(event);

        this.dateChange.emit(displayFormat);
      }
    });
  }

  toggle() {
    this.bsDatepicker.toggle();
  }
}
