import Keycloak from 'keycloak-js';
import { environment } from './environments/environment';

class NativeBridgeMock implements NativeBridge.Bridge {
  public readonly operatingSystem: NativeBridge.DartOperatingSystem = 'ios';
  public readonly operatingSystemVersion: string = 'mock';

  private pushNotificationHandler: NativeBridge.PushNotificationHandler | null = null;
  private webSocketHandler: NativeBridge.WebSocketHandler | null = null;

  private darkThemeMatch: MediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');

  // iPhone 15 Pro Max
  public safeArea: NativeBridge.EdgeInsets = {
    left: 0,
    top: 59,
    right: 0,
    bottom: 34,
  };

  private _themeSetting: NativeBridge.ThemeSetting = 'auto';

  public get themeSetting(): NativeBridge.ThemeSetting {
    return this._themeSetting;
  }

  public set themeSetting(value: NativeBridge.ThemeSetting) {
    this.internal.updateThemeSetting(value);
  }

  public themeVariant: NativeBridge.ThemeVariant = this.darkThemeMatch.matches ? 'dark' : 'light';
  public readonly internal = {
    updateSafeArea: (value: NativeBridge.EdgeInsets): void => {
      Object.freeze(value);
      this.safeArea = value;
      window.dispatchEvent(new CustomEvent('safeAreaChanged', { detail: value }));
    },

    pushNotification: (data: any): void => {
      if (!this.pushNotificationHandler) {
        console.warn('Push notification received but no handler registered', data);
      } else {
        this.pushNotificationHandler(data);
      }
    },

    websocketMessage: (action: string, data: any): void => {
      if (!this.webSocketHandler) {
        console.warn('WebSocket message received but no handler registered', action, data);
      } else {
        this.webSocketHandler(action, data);
      }
    },

    updateThemeSetting: (value: NativeBridge.ThemeSetting): void => {
      if (this._themeSetting === value) {
        return;
      }

      this._themeSetting = value;
      window.dispatchEvent(new CustomEvent('themeSettingChanged', { detail: value }));

      if (value === 'auto') {
        this.internal.updateThemeVariant(this.darkThemeMatch.matches ? 'dark' : 'light');
        this.darkThemeMatch.addEventListener('change', () =>
          this.internal.updateThemeVariant(this.darkThemeMatch.matches ? 'dark' : 'light'),
        );
      } else {
        this.internal.updateThemeVariant(value);
        this.darkThemeMatch.removeAllListeners('change');
      }
    },

    updateThemeVariant: (value: NativeBridge.ThemeVariant): void => {
      if (this.themeVariant === value) {
        return;
      }

      this.themeVariant = value;
      window.dispatchEvent(new CustomEvent('themeVariantChanged', { detail: value }));
    },
  } as const;

  private keycloak = new Keycloak({
    url: environment.keycloakUrl,
    realm: environment.keycloakRealm,
    clientId: 'webapp',
  });
  private keycloakInitialized = false;
  private keycloakInitializedListener: (() => void)[] | null = null;
  private pushNotificationPermission: NativeBridge.PermissionStatus = 'denied';

  public constructor() {
    this.darkThemeMatch.addEventListener('change', () =>
      this.internal.updateThemeVariant(this.darkThemeMatch.matches ? 'dark' : 'light'),
    );
  }

  updatePassword(): void {
    throw new Error('Method not implemented.');
  }

  public applicationReady(): void {
    console.info('Application ready');
  }

  public applicationFailure(): void {
    alert('Application failed');
  }

  public getNotificationsPermissionStatus(): Promise<NativeBridge.PermissionStatus> {
    return Promise.resolve(this.pushNotificationPermission);
  }

  public requestNotificationsPermission(): Promise<NativeBridge.PermissionStatus> {
    if (this.pushNotificationPermission === 'denied') {
      if (confirm('Do you want to allow push notifications?')) {
        this.pushNotificationPermission = 'granted';
      } else {
        this.pushNotificationPermission = 'permanentlyDenied';
      }
    }

    return Promise.resolve(this.pushNotificationPermission);
  }

  public openSettings(type: NativeBridge.AppSettingsType): void {
    alert(`Open settings: ${type}`);
  }

  public setSystemUIOverlayStyle(mode: 'light' | 'dark'): void {
    console.info('Set system UI overlay style:', mode);
  }

  public shareFile(param1: Blob | string, param2?: string, param3?: string | null): Promise<void> {
    console.info('Share file:', param1, param2, param3);
    return Promise.resolve();
  }

  public registerPushNotificationHandler(handler: NativeBridge.PushNotificationHandler): void {
    this.pushNotificationHandler = handler;
  }

  public registerWebSocketHandler(handler: NativeBridge.WebSocketHandler) {
    this.webSocketHandler = handler;
  }

  public async getAccessToken(): Promise<string> {
    if (!this.keycloakInitialized) {
      if (this.keycloakInitializedListener) {
        await new Promise<void>((resolve) => this.keycloakInitializedListener!.push(resolve));
      } else {
        this.keycloakInitializedListener = [];
        await this.keycloak.init({
          checkLoginIframe: true,
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri: location.origin + '/assets/silent-check-sso.html',
          enableLogging: !environment.production,
        });
        this.keycloakInitialized = true;
        this.keycloakInitializedListener = null;
      }
    }

    return Promise.resolve(this.keycloak.token!);
  }

  public logout(): void {
    alert('Logout');
  }

  /*public updatePassword(): Promise<void> {
    alert('updatePassword called in mock');
    return Promise.resolve();
  }*/
}

export default function installNativeMockBridge(): void {
  Object.defineProperty(window, 'native', {
    writable: false,
    enumerable: false,
    configurable: false,
    value: new NativeBridgeMock(),
  });
}
