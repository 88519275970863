import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class FlutterBridgeService {
  constructor(private router: Router) {
    this.setupFlutterNavigation();
  }

  private setupFlutterNavigation() {
    // Expose the Angular router to the window object for Flutter to access
    (window as any).angularRouter = {
      navigate: (commands: any[]) => {
        console.log('Navigation requested from Flutter:', commands);
        this.router.navigate(commands);
        return true;
      },
    };

    // Listen for custom events from Flutter
    window.addEventListener('flutterRouteChange', (event: any) => {
      const route = event.detail;
      console.log('Route change event from Flutter:', route);
      this.router.navigateByUrl(route);
    });
  }
}
