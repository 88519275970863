import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { BsDatepickerConfig, BsDatepickerDirective, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { deLocale } from 'ngx-bootstrap/locale';

@Directive({
  selector: '[appTemplateDatepicker]',
  providers: [BsDatepickerDirective],
  exportAs: 'appTemplateDatepicker', // Exportiere die Directive als Referenz
  standalone: true,
})
export class TemplateDatepickerDirective implements OnInit {
  @Input() dateFormat = 'DD.MM.YYYY';
  @Input() targetInputId: string;

  private inputElement: HTMLInputElement;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private localeService: BsLocaleService,
    public bsDatepicker: BsDatepickerDirective, // Mache bsDatepicker öffentlich
  ) {
    defineLocale('de', deLocale);
    this.localeService.use('de');
  }

  ngOnInit() {
    const config = new BsDatepickerConfig();
    config.dateInputFormat = this.dateFormat;
    config.containerClass = 'theme-default';

    this.bsDatepicker.bsConfig = config;
    this.bsDatepicker.setConfig();

    // Da die Directive direkt auf dem Input-Element ist,
    // können wir einfach el.nativeElement verwenden
    this.inputElement = this.el.nativeElement;

    this.bsDatepicker.bsValueChange.subscribe((date: Date) => {
      if (date && this.inputElement) {
        // Format for display (DD.MM.YYYY)
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const formattedDate = `${day}.${month}.${year}`;

        // Set the value in the input field
        this.renderer.setProperty(this.inputElement, 'value', formattedDate);

        // Trigger events for Template-Driven Forms
        // These events update the ngModel
        const inputEvent = new Event('input', { bubbles: true });
        const changeEvent = new Event('change', { bubbles: true });
        this.inputElement.dispatchEvent(inputEvent);
        this.inputElement.dispatchEvent(changeEvent);
      }
    });
  }

  // Füge eine öffentliche Methode zum Umschalten des Datepickers hinzu
  toggle() {
    this.bsDatepicker.toggle();
  }
}
