import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, switchMap, tap } from 'rxjs';

import { environment } from '../../../environments/environment';

import {
  Advisor,
  BankConnectionIBANInfo,
  FileUpload,
  OnBoardingCheckInfo,
  Person,
  PersonDocumentModel,
  PersonDocumentUpload,
} from '../model';

import OneTimeRequestCache from '../utils/OneTimeRequestCache';

import { ImageService } from './image.service';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  public profileDataChangedSubject = new Subject();

  private readonly loggedInPersonCache = new OneTimeRequestCache<Person>();
  private readonly profileCache = new OneTimeRequestCache<Person>();

  constructor(
    private readonly http: HttpClient,
    private readonly imageService: ImageService,
  ) {}

  /**
   * @deprecated Use getLoggedInPerson instead
   */
  public getLoggedInPerson(): Observable<Person> {
    return this.loggedInPersonCache.request(() =>
      this.http
        .get<Person>(environment.apiEndpoint + '/b2c/person')
        .pipe(
          this.imageService.loadImages(
            'profilePictureUrl',
            'advisor.profilePictureUrl',
            'advisor.company.companyLogoUrl',
            'advisor.supervisor.profilePicture',
          ),
        ),
    );
  }

  public getUserProfile(): Observable<Person> {
    return this.profileCache.request(() =>
      this.http
        .get<Person>(environment.apiEndpoint + '/b2c/person/profile')
        .pipe(
          this.imageService.loadImages(
            'profilePictureUrl',
            'advisor.profilePictureUrl',
            'advisor.company.companyLogoUrl',
            'advisor.supervisor.profilePicture',
          ),
        ),
    );
  }

  public getPersonDocuments(
    type: 'DRIVER_LICENSE' | 'USER_ID' | 'PASSPORT' | 'INSURANCE' | 'OTHER',
  ): Observable<PersonDocumentModel[]> {
    return this.http.get<PersonDocumentModel[]>(`${environment.apiEndpoint}/b2c/person/document/${type}`);
  }

  public postPersonDocuments(body: PersonDocumentUpload): Observable<{ uploadUrls: string[] }> {
    return this.http.post<{ uploadUrls: string[] }>(`${environment.apiEndpoint}/b2c/person/document`, body);
  }

  public uploadDocument(url: string, file: File): Observable<any> {
    let docType: string;

    if (!file?.type?.includes('image') && !file?.type?.includes('pdf')) {
      docType = 'multipart/form-data';
    } else {
      docType = file.type;
    }

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': docType,
      'x-amz-acl': 'private',
      'Content-Disposition': `attachment; filename="${file.name}"`,
    });

    return this.http.put(url, file, { headers, observe: 'response', responseType: 'text' });
  }

  public deletePersonDocuments(documentId: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/b2c/person/document/${documentId}`);
  }

  public clearProfileCache(): void {
    this.profileCache.clearCache();
  }

  public saveUserProfile(person: Person): Observable<void> {
    return this.http.post<void>(environment.apiEndpoint + '/b2c/person/profile', person).pipe(
      tap((): void => {
        this.profileCache.clearCache();
        this.profileDataChangedSubject.next(true);
      }),
    );
  }

  public upload(file: File): Observable<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file, file.name);

    return this.http.post<FileUpload>(environment.apiEndpoint + '/b2c/account/upload/profilepicture', formdata).pipe(
      tap((): void => {
        this.profileCache.clearCache();
        this.profileDataChangedSubject.next(true);
      }),
    );
  }

  public deleteProfilePic(): Observable<any> {
    return this.http.delete<any>(`${environment.apiEndpoint}/b2c/account/profilepicture`).pipe(
      tap((res: any): void => {
        this.profileCache.clearCache();
        this.profileDataChangedSubject.next(true);
      }),
    );
  }

  public triggerChanges(): void {
    this.profileDataChangedSubject.next(true);
  }

  public getUsersAdvisor(): Observable<Advisor> {
    return this.http
      .get<Advisor>(environment.apiEndpoint + '/b2c/person/advisor')
      .pipe(this.imageService.loadImages('profilePictureUrl', 'company.companyLogoUrl', 'supervisor.profilePicture'));
  }

  public deleteVinlivtAccount(): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/b2c/person/delete`);
  }

  public disableVinlivtAccount(): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/b2c/person/disable`);
  }

  public getBankConnectionIBANInfo(): Observable<BankConnectionIBANInfo[]> {
    return this.http.get<BankConnectionIBANInfo[]>(`${environment.apiEndpoint}/b2c/bankconnection`);
  }

  public postBankConnectionIbanInfo(iban: BankConnectionIBANInfo): Observable<BankConnectionIBANInfo> {
    return this.http.post<BankConnectionIBANInfo>(`${environment.apiEndpoint}/b2c/bankconnection`, iban);
  }

  public getBankConnectionIBANInfoByID(id: number): Observable<BankConnectionIBANInfo> {
    return this.http.get<BankConnectionIBANInfo>(`${environment.apiEndpoint}/b2c/bankconnection/${id}`);
  }

  public deleteBankConnectionIBANInfo(id: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/b2c/bankconnection/${id}`);
  }

  public onboardingCheck(): Observable<OnBoardingCheckInfo> {
    return this.http.get<OnBoardingCheckInfo>(`${environment.apiEndpoint}/b2c/onboarding/check`);
  }

  public getUrlForUpload(file: any): Observable<any> {
    return this.http.get<{ url: string }>(`${environment.apiEndpoint}/b2c/account/upload/id-document/url`, file);
  }

  public uploadPassportImg(body: any): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/b2c/account/upload/id-document`, body);
  }
}
