import { Advisor } from './advisor';
import { MandateApproval } from './broker-mandate-approval.model';

export class Person {
  id: number;
  uid: string;
  email: string = '';
  birthday: Date | string;
  firstname: string = '';
  lastname: string = '';
  profilePictureUrl: string = '';
  taxId: string = '';
  street: string = '';
  houseNumber: string = '';
  zip: number;
  city: string = '';
  mobile: string = '';
  connectedAccounts: number;
  hasProviderConsentsAccounts: boolean;
  firstLogin: boolean;
  advisor: Advisor;
  mandateApproval: MandateApproval;
  hasPoolMandate?: boolean;
  showRating: boolean;
  poolMandate: boolean;
  profilePicture: string;
  token: string;
}

export class BankConnectionIBANInfo {
  id: number;
  bankName: string;
  iban: string;
  bic: string;
}

export class PersonDocumentModel {
  assetData: AssetDataModel[];
  side: string;
}

export class AssetDataModel {
  id: number;
  mainAsset: MainAssetModel;
  attachments: PersonAttachmentModel;
  error: boolean;
  pending: boolean;
}

export class MainAssetModel {
  contentType: string;
  downloadUrl: string;
  filename: string;
  filesize: number;
  height: number;
  id: string;
  type: string;
  width: number;
}

export class PersonAttachmentModel {
  thumbnailSmall: any;
}

export class PersonDocumentUpload {
  documentType: 'DRIVER_LICENSE' | 'USER_ID' | 'PASSPORT' | 'INSURANCE' | 'OTHER';
  side: 'FRONT' | 'BACK';
  numberOfAttachmentsToAdd: number;
}
