import { Company } from './company';
import { UserConsent } from './user-consent';
import { Attachment } from './attachment.model';

export class Advisor {
  id: number;
  uid: string;
  email: string;
  firstName: string;
  lastName: string;
  profilePictureUrl: string;
  street: string;
  houseNumber: string;
  zipCode: number;
  city: string;
  phoneNumber: string;
  officePhoneNumber: string;
  personType: string;
  company: Company;
  activeConversation: boolean;
  supervisor: SupervisorModel;
  partnerLinks: PartnerLinksModel[];
}

export class PartnerLinksModel {
  description: string;
  title: string;
  partnerLinkType: string;
  url: string;
}

export class SupervisorModel {
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  profilePicture: string;
}

export class AdvisorConsentResponse {
  company: Company;
  userConsents: Array<UserConsent> = new Array<UserConsent>();
}

export class AdvisorAdvice {
  advice: string;
  contractUid: string;
  ratingType: string;
  insuranceGroup: string;
  created: Date;
  attachments: Array<Attachment>;
  spriteCoordinate: string;
}

export class AdvisorChat {
  totalMessages: number;
  unreadMessages: number;
  uid?: string;
  contractType?: string;
  lastMessage: Date;
}
