import { Injectable } from '@angular/core';
import { forkJoin, from, map, mergeMap, Observable, OperatorFunction, Subject, tap, toArray } from 'rxjs';
import { environment } from '../../../environments/environment';

class LoadedImage {
  public blobUrl: string | null = null;
  public readonly usages = new Set<any>();

  public constructor(public readonly resourceUrl: string) {}
}

/**
 * This is a service which loads images from the server which are served using a presigned S3 url. These URLs expire
 * after a while, so it might lead into missing images when the user stays longer on a page. This service prefetches
 * the images and keeps them in memory until the user leaves the page.
 */
@Injectable({
  providedIn: 'root',
})
export class ImageService {
  private readonly cache = new WeakMap<any, LoadedImage[]>();
  private readonly loaded = new Map<string, LoadedImage>();
  private readonly imageLoadedSubject = new Subject<string>();

  public loadImages<T>(...paths: string[]): OperatorFunction<T, T> {
    return mergeMap((payload) => {
      const sources = [...this.loadImagesInternal(payload, paths)];

      return forkJoin(sources)
        .pipe(toArray())
        .pipe(map(() => payload));
    });
  }

  public dispose(payload: any): void {
    if (!payload) {
      return;
    }

    this.cache.get(payload)?.forEach((loadedImage) => {
      loadedImage.usages.delete(payload);
      if (!loadedImage.usages.size) {
        console.debug('[ImageService] Disposing image blob URL: %s', loadedImage.blobUrl);
        // URL.revokeObjectURL(loadedImage.blobUrl);
        this.loaded.delete(loadedImage.resourceUrl);
      }
    });
  }

  private *loadImagesInternal(
    payload: any,
    paths: string[],
    originalPayload = payload,
  ): Generator<Observable<unknown>> {
    if (payload && paths?.length && originalPayload) {
      for (const path of paths) {
        if (path) {
          if (Array.isArray(payload)) {
            for (const current of payload) {
              if (current) {
                yield* this.loadImagesForPath(current, path, originalPayload);
              }
            }
          } else {
            yield* this.loadImagesForPath(payload, path, originalPayload);
          }
        }
      }
    }
  }

  private *loadImagesForPath(payload: any, path: string, originalPayload = payload): Generator<Observable<unknown>> {
    const split = path.indexOf('.');

    if (split === -1) {
      const value = payload[path];

      if (typeof value === 'string') {
        yield this.loadImage(originalPayload, value).pipe(tap((newUrl) => (payload[path] = newUrl)));
      }
    } else {
      const subPayload = payload[path.substring(0, split)];
      if (!subPayload) {
        return;
      }

      if (Array.isArray(subPayload)) {
        for (const current of subPayload) {
          yield* this.loadImagesForPath(current, path.substring(split + 1), originalPayload);
        }
      } else {
        yield* this.loadImagesForPath(subPayload, path.substring(split + 1), originalPayload);
      }
    }
  }

  private loadImage(payload: any, url: string): Observable<string> {
    if (!url.includes('X-Amz-Signature=')) {
      console.warn('[ImageService] Not a presigned S3 url, so skip loading it', url);
      // this is not a presigned S3 url which expires, so we do not need to prefetch it
      return from([url]);
    }

    return this.loadLoadedImage(url).pipe(
      map((loadedImage) => {
        loadedImage.usages.add(payload);
        if (!this.cache.has(payload)) {
          this.cache.set(payload, []);
        }
        this.cache.get(payload).push(loadedImage);

        return loadedImage.blobUrl;
      }),
    );
  }

  private loadLoadedImage(url: string): Observable<LoadedImage> {
    // console.debug('[ImageService] Loading image', url);
    const resourceUrl = url.substring(0, url.indexOf('?'));
    const existingImage = this.loaded.get(resourceUrl);
    if (existingImage) {
      if (existingImage.blobUrl) {
        // console.debug('[ImageService] Image already loaded, so skip loading it again', url);
        return from([existingImage]);
      } else {
        // console.debug('[ImageService] Image already requested, so wait for it to be loaded', url);
        return new Observable<LoadedImage>((subscriber) => {
          const subscription = this.imageLoadedSubject.subscribe({
            next: (loadedUrl) => {
              if (loadedUrl === resourceUrl) {
                subscriber.next(existingImage);
                subscriber.complete();
                subscription.unsubscribe();
              }
            },
            error: ({ error, loadedUrl }) => {
              if (loadedUrl === resourceUrl) {
                subscriber.error(error);
                subscription.unsubscribe();
              }
            },
          });
          subscriber.add(() => subscription.unsubscribe());
        });
      }
    } else {
      const loadedImage = new LoadedImage(resourceUrl);
      this.loaded.set(resourceUrl, loadedImage);

      return new Observable<LoadedImage>((subscriber) => {
        let corsSafeUrl: string;
        if (environment.corsBypassEndpoint && location.origin !== 'http://localhost:4200') {
          corsSafeUrl = `${environment.corsBypassEndpoint}${encodeURIComponent(url)}`;
        } else {
          corsSafeUrl = url;
        }
        fetch(corsSafeUrl, {
          cache: 'no-cache',
          method: 'GET',
          mode: 'cors',
        })
          .then((response) => response.blob())
          .then((blob) => {
            loadedImage.blobUrl = URL.createObjectURL(blob);

            console.debug('[ImageService] Image loaded from %s into %s', resourceUrl, loadedImage.blobUrl);
            subscriber.next(loadedImage);
            subscriber.complete();

            this.imageLoadedSubject.next(resourceUrl);
          })
          .catch((error) => {
            subscriber.error(error);
            this.imageLoadedSubject.error({ error, loadedUrl: resourceUrl });
          });
      });
    }
  }
}
