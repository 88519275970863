import { Component, Inject, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute, ExtraOptions, NavigationEnd, Router, RouterModule, Routes } from '@angular/router';
import { AUTH_SERVICE_TOKEN, AuthService } from './shared/service';
import { filter } from 'rxjs/operators';

@Component({
  template: '',
  standalone: false,
})
class RegisterComponent implements OnInit {
  public constructor(
    @Inject(AUTH_SERVICE_TOKEN) private readonly authService: AuthService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {}

  public ngOnInit(): void {
    if (this.authService.loggedIn) {
      this.router.navigateByUrl('/dashboard', { replaceUrl: true });
    } else {
      const { email, referral } = this.route.snapshot.queryParams;
      this.authService.register(email, referral);
    }
  }
}

const staticRoutes: Routes = [
  {
    path: 'consent/:id',
    loadComponent: () => import('./pages/consent/consent.component').then((m) => m.ConsentComponent),
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: '',
    loadComponent: () => import('./layout/app-layout.component').then((m) => m.LayoutComponent),
    children: [
      {
        path: 'dashboard',
        loadComponent: () => import('../app/pages/dashboard/dashboard.component').then((m) => m.DashboardComponent),
      },
      {
        path: 'bank-card-manager',
        loadComponent: () =>
          import('../app/pages/bank-card-manager/bank-card-manager.component').then((m) => m.BankCardManagerComponent),
        children: [
          {
            path: '',
            redirectTo: 'bank-card-overview',
            pathMatch: 'full',
          },
          {
            path: 'bank-card-overview',
            loadComponent: () =>
              import('../app/pages/bank-card-manager/bank-card-overview/bank-card-overview.component').then(
                (m) => m.BankCardOverviewComponent,
              ),
          },
          {
            path: 'bank-card-analysis',
            loadComponent: () =>
              import('../app/pages/bank-card-manager/bank-card-analysis/bank-card-analysis.component').then(
                (m) => m.BankCardAnalysisComponent,
              ),
          },
          {
            path: 'tags-manager',
            loadComponent: () =>
              import('../app/pages/bank-card-manager/tags-manager/tags-manager.component').then(
                (m) => m.TagsManagerComponent,
              ),
          },
          {
            path: ':id',
            loadComponent: () =>
              import('../app/pages/bank-card-manager/bank-card-details/bank-card-details.component').then(
                (m) => m.BankCardDetailsComponent,
              ),
          },
          {
            path: ':id/alerts',
            loadComponent: () =>
              import(
                '../app/pages/bank-card-manager/bank-card-details/bank-card-alerts/bank-card-alerts.component'
              ).then((m) => m.BankCardAlertsComponent),
          },
          {
            path: ':id/single-analyse',
            loadComponent: () =>
              import(
                '../app/pages/bank-card-manager/bank-card-details/single-bank-card-analyse/single-bank-card-analyse.component'
              ).then((m) => m.SingleBankCardAnalyseComponent),
          },
        ],
      },
      {
        path: 'person-profile',
        loadComponent: () =>
          import('../app/pages/profile/profile-data/profile-data.component').then((m) => m.ProfileDataComponent),
      },
      {
        path: 'notifications',
        loadComponent: () =>
          import('../app/pages/profile/notifications/notifications.component').then((m) => m.NotificationsComponent),
      },
      {
        path: 'settings',
        loadComponent: () =>
          import('../app/pages/profile/settings/settings.component').then((m) => m.SettingsComponent),
      },
      {
        path: 'document-upload-section',
        loadComponent: () =>
          import('../app/pages/profile/document-upload-section/document-upload-section.component').then(
            (m) => m.DocumentUploadSectionComponent,
          ),
        children: [
          { path: '', redirectTo: 'passport-document-upload', pathMatch: 'full' },
          {
            path: 'passport-document-upload',
            loadComponent: () =>
              import(
                '../app/pages/profile/document-upload-section/passport-document-upload/passport-document-upload.component'
              ).then((m) => m.PassportDocumentUploadComponent),
          },
          {
            path: 'driver-license-document-upload',
            loadComponent: () =>
              import(
                '../app/pages/profile/document-upload-section/driver-license-document-upload/driver-license-document-upload.component'
              ).then((m) => m.DriverLicenseDocumentUploadComponent),
          },
          {
            path: 'insurance-document-upload',
            loadComponent: () =>
              import(
                '../app/pages/profile/document-upload-section/insurance-document-upload/insurance-document-upload.component'
              ).then((m) => m.InsuranceDocumentUploadComponent),
          },
          {
            path: 'other-document-upload',
            loadComponent: () =>
              import(
                '../app/pages/profile/document-upload-section/other-document-upload/other-document-upload.component'
              ).then((m) => m.OtherDocumentUploadComponent),
          },
        ],
      },
      {
        path: 'confidential-access',
        loadComponent: () =>
          import('../app/pages/profile/confidential-access/confidential-access.component').then(
            (m) => m.ConfidentialAccessComponent,
          ),
      },
      {
        path: 'bank-accounts-overview',
        loadComponent: () =>
          import('../app/pages/profile/bank-accounts-overview/bank-accounts-overview.component').then(
            (m) => m.BankAccountsOverviewComponent,
          ),
      },
      {
        path: 'contracts',
        loadComponent: () => import('../app/pages/contracts/contracts.component').then((m) => m.ContractsComponent),
        children: [
          {
            path: '',
            redirectTo: 'contracts-manager',
            pathMatch: 'full',
          },
          {
            path: 'contracts-manager',
            loadComponent: () =>
              import('../app/pages/contracts/contracts-manager/contracts-manager.component').then(
                (m) => m.ContractsManagerComponent,
              ),
          },
          {
            path: 'contracts-advices',
            loadComponent: () =>
              import('../app/pages/contracts/contracts-advices/contracts-advices.component').then(
                (m) => m.ContractsAdvicesComponent,
              ),
          },
          {
            path: 'contract-creation',
            loadComponent: () =>
              import('../app/pages/contracts/contract-creation/contract-creation.component').then(
                (m) => m.ContractCreationComponent,
              ),
          },
          {
            path: 'contract-details/:uid',
            loadComponent: () =>
              import('../app/pages/contracts/contract-details/contract-details.component').then(
                (m) => m.ContractDetailsComponent,
              ),
          },
        ],
      },
      {
        path: 'vinhub',
        loadChildren: () => import('../app/pages/vinhub/vinhub.module').then((m) => m.VinhubModule),
      },
      {
        path: 'finapi-connection',
        loadComponent: () =>
          import('../app/pages/finapi-connection/finapi-connection.component').then((m) => m.FinapiConnectionComponent),
      },
      {
        path: 'wealth',
        loadComponent: () => import('../app/pages/wealth/wealth.component').then((m) => m.WealthComponent),
        children: [
          {
            path: '',
            redirectTo: 'depot-dashboard',
            pathMatch: 'full',
          },
          {
            path: 'wealth-overview',
            loadChildren: () =>
              import('../app/pages/wealth/wealth-overview/wealth-overview.module').then((m) => m.WealthOverviewModule),
          },
          {
            path: 'wealth-creation',
            loadChildren: () =>
              import('../app/pages/wealth/wealth-creation/wealth-creation.module').then((m) => m.WealthCreationModule),
          },
          {
            path: 'real-estate/real-estate-details/:id',
            loadChildren: () =>
              import(
                '../app/pages/wealth/wealth-details/real-estate/real-estate-details/real-estate-details.module'
              ).then((m) => m.RealEstateDetailsModule),
          },
          {
            path: 'vehicle/vehicle-details/:id',
            loadChildren: () =>
              import('../app/pages/wealth/wealth-details/mobility/mobility-details/mobility-details.module').then(
                (m) => m.MobilityDetailsModule,
              ),
          },
          {
            path: 'jewelry/jewelry-details/:id',
            loadChildren: () =>
              import('../app/pages/wealth/wealth-details/jewelry/jewelry-details/jewelry-details.module').then(
                (m) => m.JewelryDetailsModule,
              ),
          },
          {
            path: 'precious-metal/precious-metal-details/:id',
            loadChildren: () =>
              import(
                '../app/pages/wealth/wealth-details/precious-metal/precious-metal-details/precious-metal-details.module'
              ).then((m) => m.PreciousMetalDetailsModule),
          },
          {
            path: 'art/art-details/:id',
            loadChildren: () =>
              import('../app/pages/wealth/wealth-details/art/art-details/art-details.module').then(
                (m) => m.ArtDetailsModule,
              ),
          },
          {
            path: 'other/other-details/:id',
            loadChildren: () =>
              import('../app/pages/wealth/wealth-details/other/other-details/other-details.module').then(
                (m) => m.OtherDetailsModule,
              ),
          },
          {
            path: 'depot-dashboard',
            loadComponent: () =>
              import('../app/pages/wealth/depot-dashboard/depot-dashboard.component').then(
                (m) => m.DepotDashboardComponent,
              ),
          },
          {
            path: 'depot-account-details/:id',
            loadComponent: () =>
              import('../app/pages/wealth/depot-account-details/depot-account-details.component').then(
                (m) => m.DepotAccountDetailsComponent,
              ),
          },
        ],
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

const routerConfig: ExtraOptions = {
  useHash: false,
  enableTracing: false,
  scrollPositionRestoration: 'top',
};

@NgModule({
  imports: [RouterModule.forRoot(staticRoutes, routerConfig)],
  exports: [RouterModule],
  declarations: [RegisterComponent],
})
export class AppRoutesModule {
  constructor(private router: Router) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.notifyFlutterRouteChanged(event.urlAfterRedirects);
    });
  }

  private notifyFlutterRouteChanged(route: string): void {
    if (window.flutter_inappwebview?.callHandler) {
      window.flutter_inappwebview
        .callHandler('routeChanged', route)
        .then(() => console.log('inform flutter about route change'))
        .catch((err) => console.error('route change error', err));
    }
  }
}
