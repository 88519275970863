// modules
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

// directives
import {
  ActiveStepDirective,
  AutofocusDirective,
  DatepickerDirective,
  DelaySkeletonDirective,
  DragAndDropDirective,
  PasswordValidatorDirective,
  TemplateDatepickerDirective,
  TextareaAutosizeDirective,
} from './directive';

// services
import { UiChangeTriggersService } from './global-store';

// guards
import { AuthInterceptor } from './interceptor/auth.interceptor';

// pipes
import {
  EnumConvertedToArrayPipe,
  EnumToArrayPipe,
  GroupByPipe,
  ModalSafeAreaPipe,
  SafeSrcPipe,
  TranslatePropertyTypePipe,
  TruncatePipe,
} from './pipe';
import { authServiceProvider, SeoService } from './service';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    TranslatePropertyTypePipe,
    PasswordValidatorDirective,
    DragAndDropDirective,
    AutofocusDirective,
    ActiveStepDirective,
    TextareaAutosizeDirective,
    DelaySkeletonDirective,
    EnumToArrayPipe,
    TruncatePipe,
    ModalSafeAreaPipe,
    EnumConvertedToArrayPipe,
    GroupByPipe,
    SafeSrcPipe,
  ],
  exports: [
    TranslatePropertyTypePipe,
    PasswordValidatorDirective,
    DragAndDropDirective,
    AutofocusDirective,
    ActiveStepDirective,
    TextareaAutosizeDirective,
    DelaySkeletonDirective,
    EnumToArrayPipe,
    TruncatePipe,
    ModalSafeAreaPipe,
    EnumConvertedToArrayPipe,
    GroupByPipe,
    SafeSrcPipe,
  ],
  imports: [BsDatepickerModule.forRoot(), DatepickerDirective, TemplateDatepickerDirective],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        SeoService,
        UiChangeTriggersService,
        authServiceProvider,
        {
          provide: HTTP_INTERCEPTORS,
          multi: true,
          useClass: AuthInterceptor,
        },
      ],
    };
  }
}
