import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable, switchMap } from 'rxjs';

import { environment } from '../../../environments/environment';

import { IDamageReport, IPostDamageReport } from '../model';

@Injectable({
  providedIn: 'root',
})
export class DamageReportService {
  constructor(private http: HttpClient) {}

  public getDamageReports(contractUid: string): Observable<IDamageReport[]> {
    return this.http.get<IDamageReport[]>(`${environment.apiEndpoint}/b2c/damages/contract/${contractUid}`);
  }

  public postDamageReport(payload: IPostDamageReport, files: File[]): Observable<IDamageReport> {
    const formData: FormData = new FormData();

    // Append the payload as a JSON string
    formData.append('payload', JSON.stringify(payload));

    const filePromises = files.map((file: any) =>
      fetch(file.downloadUrl)
        .then((response: Response) => response.blob())
        .then((blob: Blob) => new File([blob], file.fileName, { type: `image/${file.extension}` }))
        .catch((error): null => {
          console.error(`Error fetching file ${file.fileName}:`, error);
          return null;
        }),
    );

    return from(Promise.all(filePromises)).pipe(
      switchMap((fileList) => {
        fileList.forEach((file): void => {
          if (file) {
            formData.append('file', file, file.name);
          }
        });

        // Send the request with the correct files
        return this.http.post<IDamageReport>(`${environment.apiEndpoint}/b2c/damages/save`, formData);
      }),
    );
  }
}
