import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StepperService {
  private formData: { [step: string]: any } = {};

  nextStep(
    currentStep: number,
    totalSteps: number,
  ): {
    currentStep: number;
    isNextDisabled: boolean;
    needsSubmit: boolean;
  } {
    if (currentStep < totalSteps - 1) {
      return { currentStep: currentStep + 1, isNextDisabled: false, needsSubmit: false };
    } else {
      return { currentStep, isNextDisabled: true, needsSubmit: true };
    }
  }

  prevStep(currentStep: number): { currentStep: number; isNextDisabled: boolean; needsReset: boolean } {
    if (currentStep > 0) {
      return { currentStep: currentStep - 1, isNextDisabled: false, needsReset: false };
    } else {
      return { currentStep: 0, isNextDisabled: false, needsReset: true };
    }
  }

  setStepData(step: string, data: any): void {
    this.formData[step] = data;
  }

  getStepData(step: string): any {
    return this.formData[step];
  }

  private selectedTypeId: number = null;

  setSelectedType(id: number): void {
    this.selectedTypeId = id;
  }

  getSelectedType(): number {
    return this.selectedTypeId;
  }

  getAllData(): any {
    return this.formData;
  }

  clearAllData(): void {
    this.formData = {};
    this.selectedTypeId = null;
  }
}
