export class VinhubChatResponse {
  page: number;
  pageSize: number;
  total: number;
  data: VinhubChat[];
  totalPages: number;
}

export class VinhubChat {
  id: number;
  participants: string[];
  type: string;
  reference: string;
  lastMessage: VinhubMessage;
  unreadMessages: number;
}

export class VinhubChatMessages {
  messages: VinhubMessage[];
  nextToken: string;
}

export class VinhubMessage {
  id: number;
  chatId: number;
  message: string;
  attachments: VinhubAttachment[];
  videoId?: number;
  authorId: string;
  source: 'USER' | 'SYSTEM';
  createdAt: string;
  dateLabel?: string;
}

export class VinhubAttachment {
  id: number;
  type: 'IMAGE' | 'PDF' | 'OTHER';
  filename: string;
  filesize: number;
  fileUrl: string;
  previewUrl: string;
}

export class VinhubSendMessage {
  message: string | null = null;
  attachments: VinhubAttachmentForSend[] | null = null;
  videoId: number | null = null;
  insuranceGroupName: string = null;
}

export class VinhubAttachmentForSend {
  type: string;
  filename: string;
  filesize: number;
}
